import React from "react";
import { graphql } from "gatsby";
import { Wrapper } from "../../style/Grid";
import { Helmet } from "react-helmet";
import config from "../../configs";
import LayoutOF from "../../components/layoutOF";
import { Hp } from "../../components/CtaModul";
import { theme } from "../../style/theme";
import { OFFER_TYPE } from "../../offers/offer-types";

const ElectricityPriceListSelection = ({ data }) => {
  // Současně máme price list 1:1 k HP
  const offers = data.mnd.offer.data.attributes.offers.filter(offer => offer.offerAssignment === OFFER_TYPE.Homepage);

  return (
    <LayoutOF headerTitle={"Elektřina – Výběr ceníku"}>
      <Helmet>
        <title>Vyberte ceník</title>
        <meta name="keywords" content="změna dodavatele plynu, změna dodavatele elektřiny, změna dodavatele energie, změna dodavatele energií" />
        <meta
          name="description"
          content="Přepis energií, plynu i elektřiny, vyřídíme online. Proveďte změnu dodavatele, přepis při stěhování, připojení novostavby nebo znovupřipojení odpojeného odběrného místa."
        />
        <meta
          name="og:description"
          content="Přepis energií, plynu i elektřiny, vyřídíme online. Proveďte změnu dodavatele, přepis při stěhování, připojení novostavby nebo znovupřipojení odpojeného odběrného místa."
        />
        <meta property="og:image" content={`${config.serverUrl}/images/og-image-hp.png`} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Product', section: 'Electricity' });
        `}</script>
        <link rel="canonical" href="https://www.mnd.cz/elektrina/vyber-ceniku"></link>
      </Helmet>

      <Wrapper background={theme.colors.grey.default} p={["30px 20px", "47px 10px 40px"]} className="content-min-height">
        <h1 className="text-[38px] text-exploratory-green-400 mb-12">Vyberte ceník</h1>

        <div className="sm:w-[462px] w-full">
          <Hp commodity={"electricity"} offers={offers.filter(o => o.commodity === "electricity")} />
        </div>
      </Wrapper>
    </LayoutOF>
  );
};

export const query = graphql`
  query ElectricityPriceListSelectionQuery {
    mnd {
      hp {
        data {
          attributes {
            fixedProducts {
              textBottom
            }
          }
        }
      }

      ...FullOffers
    }
  }
`;

export default ElectricityPriceListSelection;
